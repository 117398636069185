let testLicensePlateNumbersStr = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[A-Z])|([A-Z]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/
let testPhoneStr = /^1(3|4|5|6|7|8|9)\d{9}$/
let testIdStr = /(^\d{15}$)|(^\d{17}([0-9]|X)$)|(^[A-Z]{1}[0-9]{6}[(]{1}[a-zA-Z0-9]{1}[)]{1}$)|(^[A-Z]{1}[0-9]{8,10}$)/
let testEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function checkEmail(value) {
    if (!testEmail.test(value)) {
        return "邮箱格式不正确";
    } else {
        return false
    };
};
export function checkUId(value) {
    if (value != null && value != '' && !testIdStr.test(value)) {
        return "身份证格式不正确";
    } else {
        return false
    };
};
export function checkFloatN(value) {
    let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,3})?$/;
    if (!reg2.test(value)) {
        return "请输入0.001~100.999区间的值";
    } else {
        return false
    };
};
// 保留两位小数的任意数
export function checkNumber(value) {
    let reg = /((^[1-9]\d*)|^0)(\.\d{1,2}){0,1}$/;
    if (!reg.test(value)) {
        return '请输入正确数字值（最多保留两位小数）';
    } else {
        return false
    }
};

// 请输入正整数
export function checkNumbers(value) {
    let reg = /(^[1-9]\d*$)/;
    if (!reg.test(value)) {
        return '请输入正确数字';
    } else {
        return false
    }
};

// 请输入12以内
export function checkNumber12(value) {
    let reg = /(^[0-9]\d*$)/;
    if (value != null && (!reg.test(value) || Number(value) > 12)) {
        return '请输入正确的12以内的数字';
    } else {
        return false
    }
};
// 请输入36以内
export function checkNumber36(value) {
    let reg = /(^[0-9]\d*$)/;
    if (!reg.test(value) || Number(value) > 36) {
        return '请输入正确的36以内的数字';
    } else {
        return false
    }
};
export function checkPhone(value) {
    if (value != '' && !testPhoneStr.test(value)) {
        return "手机号码有误，请重填";
    } else {
        return false
    }
}
export function checkChese(value) {
    let regD = /^[\u4e00-\u9fa5]+$/;
    if (value != '' && !regD.test(value)) {
        return "请输入正确的中文字符";
    } else {
        return false
    }
}

export function copyStr(strs) {
    var input = document.createElement("input"); // js创建一个input输入框
    input.value = strs; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
}